<template>
  <div class="c-user-profile">
    <div v-if="isMobile">
      <Button class="p-button-text" @click="toggleUserProfile">
        <i class="pi pi-user"></i>
        <span class="c-mobile-toolbar-text">{{ fullname }}</span>
      </Button>
    </div>
    <div v-else>
      <Button
        class="p-button-text"
        type="button"
        @click="toggleUserProfile"
        aria-haspopup="true"
        aria-controls="user-profile-menu"
        data-testid="user-profile-menu"
      >
        <div class="c-user-profile">
          <div class="font-semibold text-emerald-950 dark:text-zinc-400">{{ fullname }}</div>
          <div class="text-sm leading-3 text-zinc-500 dark:text-zinc-500">{{ companyName }}</div>
        </div>
        <div class="p-button p-button-icon-only p-button-rounded dark:bg-zinc-500 dark:border-zinc-500">
          <span class="material-symbols-outlined">person</span>
        </div>
      </Button>
    </div>

    <TieredMenu id="user-profile-menu" ref="refUserProfileMenu" :model="userProfileItems" :popup="true">
      <template #item="{ item, props, hasSubmenu }">
        <a
          class="flex items-center"
          v-bind="props.action"
          :data-testid="replaceDotWithUnderscore(item?.label as string | undefined)"
        >
          <span :class="item.icon" />
          <span class="ml-2">{{ t(item.label) }}</span>
          <span
            v-if="item.shortcut"
            class="ml-auto border border-surface rounded bg-emphasis text-muted-color text-xs p-1"
            >{{ item.shortcut }}</span
          >
          <i v-if="hasSubmenu" class="pi pi-angle-right ml-auto"></i>
        </a>
      </template>
    </TieredMenu>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { MenuItem } from "primevue/menuitem";
import { useProfile } from "../menus/UserProfileMenu";
import { useAuth } from "@cumulus/event-bus";

const { t } = useI18n();
const toggle = ref(false);
const refUserProfileMenu = ref();

defineProps<{
  isMobile?: boolean;
}>();

const emit = defineEmits<{ (e: "themeChanged"): void }>();

const toggleUserProfile = (event: Event) => {
  toggle.value = !toggle.value;
  refUserProfileMenu.value.toggle(event);
};

const replaceDotWithUnderscore = (value: string | undefined): string => {
  if (value === undefined) {
    return "";
  }
  return value.toString().replaceAll(".", "_");
};

const { getUser } = useAuth();
const companyName = ref<string>();
const fullname = ref<string>();

onMounted(async () => {
  const user = await getUser();
  companyName.value = user.getCompany().name;
  fullname.value = user.getFullname();
});

const themeChanged = (): void => {
  emit("themeChanged");
};

const { getMenu } = useProfile(themeChanged);
const userProfileItems = ref<Array<MenuItem>>();
const fetchUserProfileMenu = async () => {
  userProfileItems.value = await getMenu();
};

onMounted(fetchUserProfileMenu);
</script>

<style lang="scss">
.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 14;
}
</style>
